// sass-lint:disable ImportantRule, no-important, no-misspelled-properties, no-color-literals, hex-length, indentation, zero-unit, border-zero, no-mergeable-selectors

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

@import './assets/fonts/fonts.scss';
@import './assets/icons/style.css';

@import './styles/app.scss';

// You can add global styles to this file, and also import other style files
.dp-calendar-day:disabled {
  color: #e5e7eb !important;
}
