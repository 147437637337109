// sass-lint:disable no-important
// Google places autocomplete placement
body {
  .pac-container {
    &.pac-logo {
      z-index: 10000 !important;
      position: fixed !important;
    }
  }
}
