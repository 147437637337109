// sass-lint:disable ImportantRule, no-important, no-misspelled-properties, no-color-literals, hex-length, indentation, zero-unit, border-zero, no-mergeable-selectors

@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

// You can add global styles to this file, and also import other style files
.custom-grid-theme {
  --ag-header-foreground-color: #000000;
  --ag-header-background-color: #ffffff;
  --ag-header-column-resize-handle-display: none;
  --ag-header-column-resize-handle-height: 0px;
  --ag-header-column-resize-handle-width: 0px;
  --ag-header-column-resize-handle-color: #ffffff;
  --ag-borders: none;

  .header-styling {
    font-size: medium !important;
    text-align: center;
  }

  .cell-styling {
    line-height: '55px';
  }

  .cell-border {
    border-left: 2px solid;
    border-left-color: rgba(0, 0, 0, 0.2);
    border-block: #c6ccc6;
  }

  .underline-hover {
    text-decoration: underline;
  }

  .row-class-styling {
    background-color: #f6f8f6;
    border: none;
    border-radius: 15px;
    max-height: 55px;
  }

  .cell-styling {
    max-height: 35px;
    margin-top: 10px;
    text-align: center;
  }

  .ag-row-hover::before {
    border-radius: 15px;
  }
}
